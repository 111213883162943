.partners-section {
  width: 100%;
  padding: 20px 0;
  /* background-color: #f9f9f9; */
  display: flex;
  justify-content: center;
  min-height: 30vh;
}

.partners-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  /* width: 90%; */
  justify-content: center;
  /* max-width: 1200px; */
  overflow: hidden;
}

.partners-container {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding: 10px 0;
  scrollbar-width: none; /* Hide scrollbar for better UI */
}

.partners-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.partner-logo {
  flex: 0 0 auto;
  width: 120px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: white; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease;
}

.partner-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.partner-logo:hover {
  transform: scale(1.1);
}

.scroll-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 123, 255, 0.8);
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-btn.left {
  left: -30px;
}

.scroll-btn.right {
  right: -30px;
}

.scroll-btn:hover {
  background-color: rgba(0, 86, 179, 0.9);
}

@media (max-width: 768px) {
  .scroll-btn {
      display: none;  /* Hide buttons on smaller screens */
  }
}
