.terms-container {
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 5px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    max-width: 800px;
    margin: auto;
  }
  
  .terms-content {
    font-family: 'Quicksand', 'Lato', sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .terms-content h1,
  .terms-content h2 {
    color: #0056b3;
  }
  
  .terms-content ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .terms-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-content a:hover {
    text-decoration: underline;
  }
  