.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-container h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .home-link {
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .home-link:hover {
    color: #0056b3;
  }
  