.services-container {
    font-family: 'Quicksand', 'Lato', sans-serif;
    padding: 2rem;
    background-color: #f0f8ff;
    /* Light blue background */
    color: #333;
    /* Dark text color */
    max-width: 1200px;
    margin: 0 auto;
}

.services-title {
    font-size: 4rem;
    font-family: 'Courier Prime', monospace;
    text-align: center;
    /* font-size: 3rem; */
    margin-bottom: 2rem;
    /* color: #007bff; */
    /* Vibrant blue */
}

/* .saas {
  height: 80vh;
  margin-bottom: 40vh;
}

.saas-slideshow{
  background: #555;
  height: 100%;
} */

.module-name:hover {
    color: #007bff;
}



.services-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2.5rem;
}

/* .service-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
} */
.service-card {
    background-color: lightblue;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    text-align: center;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #007bff;
    /* Vibrant blue */
}

.service-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
    /* Dark text color */
}

.service-description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    /* Medium gray */
}

.service-actions {
    /* margin-top: 1rem; */
}

.animated-section {
    /* max-width: 900px; */
    /* margin: 0 auto 40px auto; */
    /* text-align: left; */
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 2s, transform 2.5s;
    /* padding: 2rem; */
}

.animated-section.visible {
    opacity: 1;
    transform: translateY(0);
}


@media (max-width: 768px) {
    .services-container {
        padding: .5rem;
    }

    .service-card {
        padding: 1rem;
    }
}