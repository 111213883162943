.contact-container {
    font-family: 'Quicksand', 'Lato', sans-serif;
    padding: 1rem;
    background-color: #f0f8ff;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10vh;
}

.contact-form-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.office-and-social,
.map-office {
    /* margin-bottom: 2rem; */
}

.contact-contact-form,
.newsletter {
    background: linear-gradient(lavender, transparent);
    /* background-color: #fff; */
    /* padding: 2rem; */
    padding: 5%;

    border-radius: 5px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    transition: background 0.3s ease;
    position: relative;
    overflow: hidden;
    min-height: 50vh;

}

.newsletter:hover {
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), lightblue, lavender);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-contact-form:hover {
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), lightblue, lavender);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

}

.contact-contact-form:not(:hover),
.newsletter:not(:hover) {
    background: linear-gradient(lavender, transparent);

}

.form-title,
.newsletter-title,
.office-title,
.social-media-title {
    font-size: 1.875rem;
    margin-bottom: 0.75rem;
    /* color: #007bff; */
    color: #555;

}

.form-top-group {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: inherit;
}

.form-group {
    margin-bottom: 1rem;
}

.contact-container label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.contact-container input[type='text'],
.contact-container input[type='email'],
.contact-container textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    font-family: 'Quicksand', 'Lato', sans-serif;

}

.contact-container textarea {
    resize: vertical;
    font-family: 'Quicksand', 'Lato', sans-serif;

}

.submit-button {
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    border: none;
    /* background-color: #007bff; */
    background: lavender;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #007bff;
}

.subscribed-message {
    font-size: 1rem;
    color: green;
}

.office-and-social {
    display: flex;
    flex-wrap: wrap;
    gap: 10vh;
}

.office-details,
.social-media {
    flex: 1;
    background: linear-gradient(45deg, lightblue, lavender);
    /* padding: 1.5rem; */
    padding: 10%;
border: 2px solid white;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.office-details:hover,
.social-media:hover {
    background: lightblue;
}

.office-info p {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.office-info p svg {
    margin-right: 0.5rem;
}

.office-info p a {
    text-decoration: none;
}


/* .social-icons {
    display: 1;
    gap: 0.5rem;
} */

.social-icons a {
    /* font-size: 1rem; */
    color: #007bff;
    transition: color 0.3s ease;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.social-icons a:hover {
    color: #0056b3;
}

.social-icons a svg {
    font-size: 1.3rem;
    color: #333;
}

.social-icons a svg:hover {
    font-size: 1.5rem;
}




.map-office {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.map,
.office-picture {
    flex: 1;
    /* height: 500px; */
    aspect-ratio: 16/9;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.map iframe,
.office-image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.error {
    color: red;
    margin-top: 5px;
}

.office-info p a {
    text-decoration: none;
    color: #007bff;
}

@media (min-width: 576px) {

    /* .form-title,
    .newsletter-title,
    .office-title,
    .social-media-title {
        font-size: 1.75rem;
    } */

    .form-group {
        margin-bottom: 1.25rem;
    }

    /* label {
        font-size: 1.1rem;
    } */

    .contact-container input[type='text'],
    .contact-container input[type='email'],
    .contact-container textarea {
        padding: 0.75rem;
        width: 95%;
        /* font-size: 1rem; */
    }

    .submit-button {
        padding: 0.75rem 2rem;
        /* font-size: 1rem; */
    }

    /* .map,
    .office-picture {
        height: 500px;
    } */
}

@media (min-width: 768px) {
    .contact-container {
        padding: 2rem;
    }

    .contact-contact-form,
    .newsletter,
    .office-details,
    .social-media {
        flex: 1;
    }

    .contact-form-section {
        flex-direction: row;
        gap: 2rem;
    }

    .contact-contact-form {
        max-width: 100%;
    }

    .newsletter {
        max-width: 100%;
    }

    .office-and-social {
        flex-direction: row;
        gap: 1rem;
    }

    /* .map-office {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .map,
    .office-picture {
        flex: 1;
        height: 400px;
        border-radius: 5px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .map iframe,
    .office-image {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    } */

}

@media (min-width: 992px) {
    .contact-container {
        gap: 2rem;
    }
}

@media (max-width: 850px) {

    .form-top-group {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .contact-container {
        padding: .5rem;
    }

    /* .contact-contact-form,
    .newsletter {
        padding: 1rem;
    } */

    .contact-container input[type='text'],
    .contact-container input[type='email'],
    .contact-container textarea {
        padding: 0.75rem;
        width: 95%;
        /* font-size: 1rem; */
    }

    .map-office {
        flex-direction: column;
        gap: 10vh;
    }
}

/* @media (max-width: 480px) {
.contact-contact-form {
    align-content: center;
    padding: 10%;
}
} */



.contact-contact-form,
.newsletter {

    position: relative;
    /* padding: 20px; */
    border-top: 3px solid white;
    border-bottom: none;
    transition: all 0.3s ease-in-out;
}


/* Left and right gradient borders */
.contact-contact-form::before,
.contact-contact-form::after,
.newsletter::before,
.newsletter::after {
    content: "";
    position: absolute;
    top: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom, white, #f0f8ff);
    transition: opacity 0.3s ease-in-out;
}

.contact-contact-form::before,
.newsletter::before {
    left: 0;
}

.contact-contact-form::after,
.newsletter::after {
    right: 0;
}


.contact-contact-form:hover,
.newsletter:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
    border: 3px solid white;

}


/* Hide gradient borders on hover */
.contact-contact-form:hover::before,
.contact-contact-form:hover::after,
.newsletter:hover::before,
.newsletter:hover::after {
    opacity: 0;
}