.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Quicksand', 'Lato', sans-serif;
    line-height: 1.6;
    color: #333;
}

.privacy-policy-container h1,
.privacy-policy-container h2,
.privacy-policy-container h3,
.privacy-policy-container h4 {
    margin-top: 20px;
    color: #000;
}

.privacy-policy-container p {
    margin-bottom: 16px;
}

.privacy-policy-container ul {
    padding-left: 20px;
    margin-bottom: 16px;
}

.privacy-policy-container ul li {
    margin-bottom: 8px;
}

.privacy-policy-container a {
    color: #1e90ff;
    text-decoration: none;
}

.privacy-policy-container a:hover {
    text-decoration: underline;
}
