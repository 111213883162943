.project-details-container {
    font-family: 'Quicksand', 'Lato', sans-serif;
    padding: 2rem;
    background-color: #f0f8ff;
    /* Light blue background */
    color: #333;
    /* Dark text color */
    max-width: 1200px;
    margin: 0 auto;
}

.project-details-header {
    /* text-align: center; */
    /* margin-bottom: 2rem; */
    display: flex;
    flex-direction: row-reverse;
}

.project-details-content {
    flex: 1;
    align-content: center;
    padding: 0 40px;
}

.project-details-title {
    font-family: 'Muli', 'Poppins', sans-serif;
    font-size: 1.875rem;
    /* color: #007bff; Vibrant blue */
    margin-bottom: 0.5rem;
}

.project-details-type {
    font-family: 'Quicksand', 'Lato', sans-serif;
    font-size: 1.375rem;
    color: #007bff;
    /* Vibrant blue */
    margin-bottom: 0.5rem;
}

.project-details-carousel {
    position: relative;
    flex: 1;
    /* margin-bottom: 2rem; */
    align-content: center;
}

.project-details-image {
    width: 100%;
    height: auto;
    /* max-height: 400px; */
    object-fit: cover;
    /* border-radius: 8px; */
}

.carousel-button {
    position: absolute;
    top: 50%;
    /* transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 2rem;
    border-radius: 50%;
    user-select: none;
    z-index: 10; */
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 1rem;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1;
}

.prev-button {
    left: 0;
}

.next-button {
    right: 0;
}

.project-details-body {
    padding: .5rem;
}

.project-details-section {
    /* margin-bottom: 2rem; */
}

.section-title {
    font-size: 1.5rem;
    color: #333;
    /* Dark text color */
    /* margin-bottom: 1rem; */
}

.project-details-section-content {
    font-family: 'Quicksand', 'Lato', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5;
    color: #555;
    /* Medium gray */
}

.description {
    font-family: 'Quicksand', 'Lato', sans-serif;
    font-size: 1rem;
    /* font-size: 0.875rem; */
    /* line-height: 1.5; */
    color: #333;
    /* Medium gray */
}

.duration {
    font-family: 'Quicksand', 'Lato', sans-serif;
    font-size: 1.25rem;
    /* line-height: 1.5; */
    color: #555;
    /* Medium gray */
}

.status {
    font-family: 'Quicksand', 'Lato', sans-serif;
    font-size: 1.25rem;
    /* line-height: 1.5; */
    color: #555;
    /* Medium gray */
}

.section-list {
    list-style-type: disc;
    margin-left: 2rem;
}

.section-list li {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.preview-links {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;
}

/* 
.preview-link {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #007bff; 
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.preview-link:hover {
    background-color: #0056b3; 
}

.preview-link .icon {
    margin-right: 0.5rem;
} */

@media (max-width: 768px) {
    .project-details-container {
        padding: .5rem;
    }

    .project-details-header {
        flex-direction: column;
    }

    .project-details-content {
        padding: 0 10px;
        text-align: center;
    }
}