.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f0f2f5; /* Light background color */
  }
  
  .loading-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 2s infinite;
  }
  
  .loading-logo {
    width: 80px;
    height: 80px;
    /* margin-right: 10px; */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .loading-animation {
    margin-top: 20px;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, #4A6FB1, #00BFFF);
    animation: loadingBar 2s infinite;
  }
  
  @keyframes loadingBar {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  