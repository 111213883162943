.delete-account-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.product-logo {
    max-width: 100px;
    margin-bottom: 20px;
}

.input-field {
    width: 100%;
    margin-bottom: 15px;
}

.login-button, .google-login-button {
    width: 100%;
    margin-bottom: 10px;
}

.ant-modal-title {
    font-size: 18px;
    font-weight: bold;
}

.ant-modal-body {
    font-size: 16px;
}

.ant-btn-primary {
    background-color: #4A6FB1;
    border-color: #4A6FB1;
}

.ant-btn-default {
    color: #4A6FB1;
    border-color: #4A6FB1;
}
