.alternating-team-list {
  display: flex;
  flex-direction: column;
  gap: 4rem; /* Space between each row */
  padding: 2rem;
}

.team-member-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
  
}


.aligned-left {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    background: linear-gradient(45deg, lavender, lightblue);
    
}

.aligned-right {
    flex-direction: row;
    justify-content: space-evenly;
    background: linear-gradient(45deg, lightblue, lavender);
}


.team-member-link {
  text-decoration: none;
  color: inherit;
  width: 60%; /* Each section takes 60% of the row width */
}

.team-member-card {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid white;
}

.team-member-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  background: lightblue;
}

.image-container {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #007bff;
}

.team-member-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member-info {
  display: flex;
  flex-direction: column;
}

.team-member-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.team-member-position {
  font-size: 1rem;
  color: #666;
}

/* Responsiveness */
@media (max-width: 768px) {
  .team-member-link {
    width: 100%; /* Full width for smaller screens */
  }

  .team-member-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .image-container {
    margin-bottom: 1rem;
  }
}



/* Animations */

@keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-10%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(10%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slideInLeft 0.7s ease-out;
  }
  
  .slide-in-right {
    animation: slideInRight 0.7s ease-out;
  }
  
  .fade-in-left {
    animation: fadeInLeft 0.7s ease-out;
  }
  
  .fade-in-right {
    animation: fadeInRight 0.7s ease-out;
  }

/* 

.team-list-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
  }
  
  .team-member-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .align-left {
    flex-direction: row;
  }
  
  .align-right {
    flex-direction: row-reverse;
  }
  
  .team-member-link {
    text-decoration: none;
    color: inherit;
    width: 100%;
  }
  
  .team-member-card {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .image-container {
    flex-shrink: 0;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .team-member-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .team-member-info {
    display: flex;
    flex-direction: column;
  }
  
  .team-member-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
  
  .team-member-position {
    font-size: 1rem;
    color: #555;
  }
  
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-10%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(10%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .slide-in-left {
    animation: slideInLeft 0.7s ease-out;
  }
  
  .slide-in-right {
    animation: slideInRight 0.7s ease-out;
  }
  
  .fade-in-left {
    animation: fadeInLeft 0.7s ease-out;
  }
  
  .fade-in-right {
    animation: fadeInRight 0.7s ease-out;
  }
   */