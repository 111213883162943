.about-container {
    font-family: 'Quicksand', 'Lato', sans-serif;
    padding: 2rem;
    background-color: #f0f8ff;
    /* Light blue background */
    color: #333;
    /* Dark text color */
    max-width: 1200px;
    margin: 0 auto;
}



.about-mission-top,
.about-vision-top,
.about-history-top {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    height: 80vh;
    /* margin-top: 10vh; */
    margin-bottom: 10vh;
}


.about-milestones-top {
    /* display: flex; */
    /* flex-direction: row; */
    /* gap: 2rem; */
    /* height: 80vh; */
    margin-top: 10vh;
    margin-bottom: 10vh;
}


.about-sections {
    /* background: linear-gradient(45deg, lightblue, lavender); */
    align-content: center;
    padding: 10%;
    border: 2px solid white;

}

.about-sections:hover {
    background: linear-gradient(45deg, transparent, lightblue);
    border: none;

}


.image-sections-history {

    width: 125%;
}

.image-sections-history,
.image-sections-mission,
.image-sections-vision {
    align-content: center;
    padding: 20px;

}

.image-sections-history:hover {
    background: linear-gradient(45deg, lavender, transparent);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 2px solid white;

}

.image-sections-mission:hover {
    background: linear-gradient(45deg, lavender, transparent);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 2px solid white;

}

.image-sections-vision:hover {
    background: linear-gradient(45deg, transparent, lightblue);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 2px solid white;


}


.about-history:hover {
    background: linear-gradient(45deg, lavender, transparent);
    box-shadow: none;

}

.about-milestones:hover {
    background: linear-gradient(45deg, transparent, lightblue);
    /* background: linear-gradient(45deg, lavender, transparent); */

    box-shadow: none;

}

.about-mission:hover {
    background: linear-gradient(45deg, lavender, transparent);
    box-shadow: none;

}

.about-vision:hover {
    background: linear-gradient(45deg, transparent, lightblue);
    box-shadow: none;

}




.services-distributed {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    /* height: 80vh; */
}

.about-mission,
.about-vision,
.about-history,
.about-milestones {
    background: linear-gradient(45deg, lightblue, lavender);
    /* padding: 1.5rem; */
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* margin-bottom: 5rem; */
    animation: fadeIn 1s ease-in;

}

.team-section-content {
    font-size: 1.2rem;
    /* line-height: 1.8; */
    color: #555;
    animation: fadeIn 1s ease-in;
    padding: 2rem;
    border: 2px solid white;
}

.about-team {
    background: linear-gradient(45deg, #f0f8ff, lavender);
    /* padding: 2rem; */
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    animation: fadeIn 1s ease-in;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
}

/* .about-mission :hover,
.about-vision :hover,
.about-history :hover {
    background-color: lightblue;
    /* padding: 2rem; */
/* border-radius: 8px; */
/* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
/* margin-bottom: 2rem;
    animation: fadeIn 1s ease-in;
    transition: background 0.3s ease;
    position: relative;
    overflow: hidden; */
/* }  */

.about-team :hover {
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), #f0f8ff, lavender);

}

/* Optional: To make sure the element's background smoothly transitions back */
/* .about-team:not(:hover) {
    background: lavender;
} */

.about-mission h2,
.about-vision h2,
.about-history h2,
.about-milestones h2,
.about-team h2 {
    font-size: 1.875rem;
    /* font-family: 'Roboto', sans-serif; */
    /* color: #007bff; */
    /* margin-bottom: 1rem; */
    color: #333;
    display: flex;
    align-items: flex-start;
    font-family: 'Muli', 'Poppins', sans-serif;

}
.about-mission p,
.about-vision p,
.about-history p,
.about-milestones p {
    font-size: 1rem;
    font-family: 'Quicksand', 'Lato', sans-serif;

}

.section-content {
    font-size: 1.2rem;
    /* line-height: 1.8; */
    color: #555;
    animation: fadeIn 1s ease-in;
}

.section-content li {
    list-style-type: none;
}




.about-history ul,
.about-milestones ul,
.about-team ul {
    list-style-type: none;
    /* margin-left: 2rem; */
    color: #555;
}

.about-history li,
.about-milestones li,
.about-team li {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-family: 'Quicksand', 'Lato', sans-serif;
}


.about-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #007bff;
    color: white;
    font-size: 1.5rem;
    border-radius: 50%;
    margin-top: 3px;
    /* margin-right: 1rem; */
}

.team-icon {
    margin-left: .5rem;
}

.icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #007bff;
    color: white;
    font-size: 1.8rem;
    border-radius: 50%;
    /* margin-right: 1rem; */
}

.team-photo {
    width: 100%;
    /* max-width: 600px; */
    height: auto;
    border-radius: 5px;
    margin-top: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1.5s ease-in;
}



@keyframes slideInDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .about-container {
        padding: .5rem;
    }

    .about-mission,
    .about-vision,
    .about-history,
    .about-milestones {
        padding: 1rem;
    }

    /* .about-team {
        padding: 1rem;
    } */

    .team-section-content {
        padding: 1rem;
    }

    .about-icon {

        /* font-size: 1.4rem; */
    }

    .matrix-effect {
        /* font-size: 2.5rem; */
    }


    .about-hero h1 {
        /* font-size: 2.5rem; */
    }

    .about-hero p {
        /* font-size: 1.2rem; */
    }

    .about-mission-vision {
        flex-direction: column;
        gap: 0;
    }

    .about-mission h2,
    .about-vision h2,
    .about-history h2,
    .about-milestones h2,
    .about-team h2 {
        /* font-size: 2rem; */
        align-items: baseline;
    }

    .section-content {
        /* font-size: 1.4rem; */
    }

    .services-distributed {
        flex-direction: column;
        gap: 0;
    }
}

@media (max-width: 480px) {
    .matrix-effect {
        /* font-size: 2rem; */
    }

    .about-hero h1 {
        /* font-size: 2rem; */
    }

    .about-hero p {
        /* font-size: 1rem; */
    }

    .about-mission h2,
    .about-vision h2,
    .about-history h2,
    .about-milestones h2,
    .about-team h2 {
        /* font-size: 1.5rem; */
    }

    .section-content {
        /* font-size: 1.2rem; */
    }


    .about-history-top {
        flex-direction: column-reverse;
        gap: 2rem;
        height: auto;
        margin-top: 10vh;
        margin-bottom: 10vh;

    }

    .about-mission-top {
        flex-direction: column-reverse;
        gap: 2rem;
        height: auto;
        /* margin-top: 10vh; */
        margin-bottom: 10vh;
    }

    .about-vision-top {
        flex-direction: column;
        gap: 2rem;
        height: auto;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }

    .about-milestones-top {
        /* display: flex; */
        /* flex-direction: row; */
        /* gap: 2rem; */
        /* height: 80vh; */
        margin-top: 10vh;
        margin-bottom: 10vh;
    }


    .about-sections {
        /* background: linear-gradient(45deg, lightblue, lavender); */
        align-content: center;
        padding: 10%;
    }


    .image-sections-history,
    .image-sections-mission,
    .image-sections-vision {
        align-content: center;
        /* padding: 20px; */
        /* height: 100%; */
        width: auto;

    }

    .image-sections-history img,
    .image-sections-mission img,
    .image-sections-vision img {
        z-index: 10;
        position: relative;
        margin-bottom: -125px;
        width: 100%;

    }

    .image-sections-vision:hover {
        background: linear-gradient(45deg, lavender, transparent);
    }

    .about-vision:hover {
        background: linear-gradient(45deg, lavender, transparent);
    }

}


.binoculars-img {
    transform: rotateY(180deg);
  }



