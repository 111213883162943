/* Importing Google Fonts with alternatives */
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;600&family=Quicksand:wght@300;400;600&family=Poppins:wght@400;600&family=Lato:wght@300;400;600&display=swap');


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Apply Quicksand (with Lato as the fallback) to the body text */
body {
  font-family: 'Quicksand', 'Lato', sans-serif;
}

/* Apply Muli (with Poppins as the fallback) to titles */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Muli', 'Poppins', sans-serif;
  font-weight: 600; /* You can adjust this depending on the style */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



:root {
  --primary: #007bff;
  /* Adjust as needed */
  --secondary: #0056b3;
  /* Adjust as needed */
  --primary-text: #ffffff;
  /* Adjust as needed */
}

/* AntD Primary Button */
.ant-btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--primary-text) !important;
}

/* AntD Primary Button on Hover */
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}



.ant-float-btn-primary .ant-float-btn-body {
  background-color: var(--primary) !important;
  /* Ensures consistency for all buttons */
  border: 2px solid var(--secondary) !important;
  /* Ensures consistency for all buttons */
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected {
  color: var(--primary);
  /* Change text color */
  /* border-bottom: 2px solid var(--primary); Underline */
  font-weight: bold;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover {
  color: var(--secondary);
  /* Change text color on hover */
  background-color: transparent;
  /* Prevent unwanted background */
  /* border-bottom: 2px solid var(--secondary); Underline on hover */
}
