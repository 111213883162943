@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Courier+Prime:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mainContainer {
  background-color: #f0f8ff;
  /* background-color: #fff; */
}

/* .zoom-80 {
  transform: scale(0.8);
    transform-origin: top left; 
    width: 125%; 
    height: 125%; 
} */


.center-placeholder {
  position: fixed; /* Ensures the div covers the entire screen */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center; /* Horizontally center the image */
  align-items: center; /* Vertically center the image */
  background-color: #f0f8ff; /* White background */
  z-index: 999; /* Ensures it is on top of other content */
  flex-direction: column;
}



.center-placeholder img {
  opacity: 0.3; /* Adjust this for transparency */
  max-width: 50%; /* Make sure the logo fits well */
  max-height: 50%; /* Adjust size to fit well */
}


.slow-loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: rgb(51, 0, 0);
}

.center-loading-animation {
  opacity: 0.3; /* Adjust this for transparency */
  margin-top: 20px;
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #4A6FB1, #00BFFF);
  animation: centerLoadingBar 2s infinite;
}

@keyframes centerLoadingBar {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(150%);
  }
}


.center-loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; /* Space between dots */
}

.center-loading-dots span {
  width: 8px;
  height: 8px;
  background-color: black; /* Dot color */
  border-radius: 50%; /* Makes it a circle */
  animation: dotPulse 1.5s infinite;
}

.center-loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.center-loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}
.center-loading-dots span:nth-child(4) {
  animation-delay: 0.6s;
}
.center-loading-dots span:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes dotPulse {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.3;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* required many places */

.matrix-effect {
  /* font-size: 3.5rem; */
  font-family: 'Quicksand', 'Lato', sans-serif;
  /* color: #007bff; Vibrant blue */
  margin-bottom: 0.5rem;
  /* white-space: nowrap; */
  overflow: hidden;
  /* animation: typing 2s steps(30, end), blink-caret 0.75s  infinite; */
  animation: fadeIn .5s ease-in, 4s steps(40, end) forwards, blink-caret 0.75s step-end infinite;

}


.all-hero {
  text-align: center;
  height: 80vh;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.all-hero h1 {
  font-size: 3rem;
  font-family: 'Muli', 'Poppins', sans-serif;
  /* color: #007bff; */
  color: #1b1b1b;
  /* Vibrant blue */
  margin-bottom: 2rem;
  animation: slideInDown 1.5s ease-out;
}


.all-hero p {
  font-size: 1.375rem;
  color: #555;
  /* Medium gray */
  /* animation: slideInUp 1s ease-out; */
}



.about-hero {
  text-align: center;
  padding: 2rem 0;
  margin-bottom: 2rem;
  /* animation: fadeIn 1s ease-in; */
}


.about-hero h1 {
  font-size: 4rem;
  /* font-family: 'Roboto', sans-serif; */
  /* color: #007bff; */
  /* Vibrant blue */
  margin-bottom: 2rem;
  animation: slideInDown 1.5s ease-out;
}


.about-hero p {
  font-size: 1.5rem;
  color: #555;
  /* Medium gray */
  /* animation: slideInUp 1s ease-out; */
}


/* .saas {
  height: 80vh;
  margin-bottom: 40vh;
} */

.saas-home {
  height: 80vh;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.saas-slideshow{
  /* height: 80vh; */
  background: #555;
  height: 100%;
}








.saas {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  margin-top: 10vh;
  margin-bottom: 10vh;
    width: 100%;
  padding: 1rem;
  /* gap: 2rem; */
  box-sizing: border-box;
  /* background: lavender; */
  background: linear-gradient( lavender, transparent);
  border-radius: 5px;
  
  position: relative;
  /* padding: 20px; */
  border-top: 3px solid black;
  border-bottom: none;
  transition: all 0.3s ease-in-out;
}


/* Left and right gradient borders */
.saas::before,
.saas::after {
    content: "";
    position: absolute;
    top: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom, black, #f0f8ff);
    transition: opacity 0.3s ease-in-out;
}

.saas::before {
    left: 0;
}

.saas::after {
    right: 0;
}


.saas:hover {
  background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), #f0f8ff, lavender);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  border: 3px solid black;

}


/* Hide gradient borders on hover */
 .saas:hover::before,
 .saas:hover::after {
    opacity: 0;
}


.saas-big-text{
  font-size: 1rem !important;
}
.saas-small-text{
  font-size: 0.875rem !important;
}


/* Top Section */
.top {
  text-align: center;
}

.top h3 {
  margin: 0.5rem 0;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 1.375rem;
       color: #333;
}
.top h4 {
  margin: 0.5rem 0;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 1.25rem;
       color: #333;
     text-transform: uppercase;
}

.top p {
  font-size: 1.2rem;
  color: #666;
}

.top-buttons {
  margin-top: 1rem;
}

.top-buttons button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4a6fb1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.top-buttons button:hover {
  background-color: #3c5a91;
}

/* Middle Section */
.middle {
  display: flex;
  flex: 1;
  gap: 1rem;
}
.middle-empty {
  display: flex;
  flex: 1;
 align-items: center;
 padding: 2rem;
}
.middle-empty-paragraph {
 font-size: 1.875rem;
 font-family: 'Quicksand', 'Lato', sans-serif;

}

.middle-left {
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle-left img {
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.middle-right {
  flex: 1;
  display: flex;
  padding: 2rem;
  /* align-items: center; */
  /* justify-content: center; */
}

/* .middle-right div {
  list-style-type: none;
  padding-left: 1.5rem;
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
} */
.middle-right ul {
  list-style-type: none;
  padding-left: 1.5rem;
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
  min-width: -webkit-fill-available;
  font-family: 'Quicksand', 'Lato', sans-serif;

}

/* Bottom Section */
.bottom {
  display: flex;
  justify-content: end;
  gap: 1rem;
  align-items: center;
}

.bottom .visit-site {
  padding: 0.7rem 2rem;
  font-size: 1rem;
  background-color: #4a6fb1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bottom .visit-site:hover {
  background-color: #3c5a91;
}

/* Responsive Design */
@media (max-width: 768px) {
  .middle {
    flex-direction: column;
  }

  .saas{
    padding: 0.5rem;
  }

  .middle-left img {
   
    border-radius: 4px;
  }

  .middle-left, .middle-right {
    flex: unset;
    /* width: 100%; */
    /* margin: 0 auto; */
  }

  .middle-right ul {
    text-align: left;
  }

  .top h3 {
    /* font-size: 1.25rem; */
  }
  .top h4 {
    /* font-size: 1.1rem; */
  }
}

@media (max-width: 480px) {

  .top p {
    /* font-size: 1rem; */
  }

  .top-buttons button,
  .bottom .visit-site {
    padding: 0.5rem 1.5rem;
    /* font-size: 0.9rem; */
  }
}





.changing-btn {
  /* position: absolute;
  top: 50%; */
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
}

.changing-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.changing-btn.left {
  left: 10px;
}

.changing-btn.right {
  right: 10px;
}

@media (max-width: 768px) {

  .changing-btn {
    width: 30px;
    height: 30px;
    /* font-size: 1.2rem; */
  }
}

@media (max-width: 480px) {

  .changing-btn {
    width: 25px;
    height: 25px;
    /* font-size: 1rem; */
  }
}


.saas-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px; /* Space between elements */
  width: 100%;
}

.saas-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.saas-btn-left:hover,
.saas-btn-right:hover {
  background-color: #0056b3;
}

.saas-heading {
  font-size: 1.5rem;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  /* white-space: nowrap; */
}




.view-all-projects {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
  font-size: 1.25rem;
    color: hsl(211, 100%, 50%);
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;
  font-family: 'Quicksand', 'Lato', sans-serif;

}

.view-all-projects:hover {
  color: #116dcf;
}

.project-card {
  background-color: white;
  border-radius: 5px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

.action-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  /* Vibrant blue button */
  color: #007bff;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 1rem;
  margin-top: 1rem;
  border: 1px solid #007bff;
  /* Border color and thickness */
  transition: background-color 0.5s ease, color 0.3s ease;
  background-color: lavender;
  overflow: hidden;
  /* transition: transform 0.3s ease, opacity 0.3s ease; */
  /* Smooth transition for background and text color */
}

.action-button:hover {
  background-color: #007bff;
  border: 1px solid #007bff;

  /* Darker shade of blue on hover */
  color: white;
}




/* Modal styles */
.modal {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: lavender;
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 100%;
  text-align: center;
  position: relative;
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}

.contact-form input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.submit-button {
  padding: 0.25rem .5rem;
  font-size: 1rem;
  background-color: lavender;
  /* Vibrant blue button */
  color: #007bff;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #007bff;
  /* Border color and thickness */
  transition: background-color 0.5s ease, color 0.3s ease;
  /* Smooth transition for background and text color */
  font-family: 'Quicksand', 'Lato', sans-serif;

}

.submit-button:hover {
  background-color: #007bff;
  border: 1px solid #007bff;

  /* Darker shade of blue on hover */
  color: white;
}

.error {
  color: red;
  margin-top: 5px;
}


@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

@keyframes typing {
  from {
      width: 0
  }

  to {
      width: 100%
  }
}

@keyframes blink-caret {

  from,
  to {
      border-color: transparent
  }

  50% {
      border-color: orange;
  }
}


/* ::-webkit-scrollbar {display:none;}  */

/* For Firefox */
html {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  /* scrollbar-color:var(--primary) var(--secondary)  ; */
  /* // Colors for thumb and track */
  }