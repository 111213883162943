.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
  }
  
  .error-content {
    text-align: center;
    max-width: 400px;
  }
  
  .error-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  .ant-btn-primary {
    background-color: #721c24;
    border-color: #721c24;
    color: #fff;
  }
  