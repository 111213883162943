.staff-profile-container {
    font-family: 'Quicksand', 'Lato', sans-serif;
    padding: 1rem;
    margin: 0 auto;
    max-width: 800px;
}

.staff-profile-card {
    border-radius: 5px;
    overflow: hidden;
    /* background-color: #ffffff; White background for the card */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Subtle shadow for the card */
}

  
.staff-profile-image-container-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    overflow: hidden;
    align-self: center;
    /* width: 120px; Circular container size
    height: 120px; Circular container size */
    border-radius: 50% 0 0 50%; /* Make the image circular */
    flex-direction: row;
    gap: 20px;
}
.staff-profile-image-container {
    width: 150px; 
    height: 150px; 
    /* border-radius: 50%; */

}

.staff-profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%; /* Make the image circular */
}

.staff-profile-details {
    padding: 1.5rem;
}

.staff-profile-name {
    font-size: 1.875rem;
    color: #1B1B1B;
    margin-bottom: 0.5rem;
    text-align: center;
}

.staff-profile-position {
    font-size: 1.25rem;
    color: #555;
    margin-bottom: 1rem;
    text-align: center;
}

.staff-profile-bio {
    margin-bottom: 1rem;
}

.staff-bio-section {
    /* margin-bottom: 0.5rem; */
    padding: 0.5rem;
    border-radius: 5px;
    /* background-color: #e3f2fd; */
}

.bio-title {
    font-size: 1.375rem;
    color: #333;
}

.bio-content {
    /* font-family: 'Roboto', sans-serif; */
    font-size: 1rem;
    margin-top: 0.5rem;
    line-height: 1.5; /* Improved readability */
    color: #555;
}

/* Contact Styles */
.staff-profile-contact {
    margin-top: 1rem;
    padding: 0.5rem;
    /* text-align: center; Center align the contact information */
    flex-direction: row;
}

.staff-profile-contact p {
    margin-bottom: 0.5rem;
}

.staff-profile-contact p a{
    text-decoration: none;
    color: #0077b5;
    align-items: flex-start;
}

.staff-profile-contact p a:hover{
    color: #1B1B1B;
}

.staff-profile-linkedin {
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
    color: #0077b5;
    text-decoration: none;
}

.linkedin-icon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .staff-profile-container {
        padding: .5rem;
        margin: 0;
    }

    .staff-profile-details {
        padding: 0;
    }
    .staff-profile-name {
        /* font-size: 1.7rem; */
    }

    .staff-profile-position {
        /* font-size: 1.3rem; */
    }

    .staff-bio-section {
        padding: 0.5rem;
    }

    .bio-title {
        /* font-size: 1.2rem; */
    }

    .bio-content {
        /* font-size: 0.9rem; */
    }
}
