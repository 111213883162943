.tanzator-service-item-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 5px;
    font-family: 'Quicksand', 'Lato', sans-serif;
    border: 2px solid white;
}

.tanzator-service-item-container:hover .tanzator-service-image img {
    transform: scale(1.2);
    /* Scale image on hover */
}

.tanzator-service-image {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.tanzator-service-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    /* Smooth scale on hover */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*Weak transparent overlay*/
    background: radial-gradient(circle,
            rgba(29, 44, 51, 0.755) 0%,
            rgba(29, 44, 51, 0.2) 100%);

    /* background-color: rgba(29, 44, 51, 0.555); */
    /* background-color: rgba(20, 52, 85, 0.349); */
    /* background-color: rgba(0, 123, 255, 0.3); original */
    /* background-color: #003bbf77; */

    /* background-color: rgba(230, 230, 250, 0.4);  */
    transition: background-color 0.4s ease;
}

.overlay-title {
    position: absolute;
    font-size: 1.25rem;
    line-height: 2.5rem;
    font-weight: 500;
    color: white;
    /* color: rgb(0, 123, 255); Weak transparent overlay */
    font-family: 'Muli', 'Poppins', sans-serif;
    text-transform: uppercase;
    z-index: 10;
    text-align: center;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .overlay-title {
    position: absolute;
    font-size: 1.5rem;
    color: white;
    text-transform: uppercase;
    z-index: 10;
    text-align: center;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.tanzator-service-content {
    position: absolute;
    width: 0;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f8ff;
    opacity: 0;
    transition: width 0.7s ease, height 0.7s ease, opacity 0.7s ease;
    /* Smooth appearance */
    z-index: 9;
    /* Behind the h3 */
    border: 2px solid #007bff;
}

.tanzator-service-item-container.hovered .tanzator-service-content {
    width: 75%;
    /* Set width to 75% */
    height: 75%;
    /* Set height to 75% */
    opacity: 1;
    top: 50%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* Adjust positioning */
    padding: .5rem;
}

.tanzator-service-content h4 {
    text-transform: uppercase;
    text-align: center;
    color: black;
    font-size: 1.25rem;
    font-family: 'Muli', 'Poppins', sans-serif;

}

.tanzator-service-content p {
    color: #666;
    text-align: center;
    margin: 0 0 .5rem;
    font-size: 0.875rem;
    font-family: 'Quicksand', 'Lato', sans-serif;

}

.tanzator-service-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 5px;


}

.modal-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* .modal-button.inquire {
    background-color: #007bff;
    color: white;
}

.modal-button.work-now {
    background-color: #28a745;
    color: white;
}

.modal-button:hover {
    background-color: #0056b3;
} */

.modal-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-family: 'Quicksand', 'Lato', sans-serif;
    /* Vibrant blue button */
    color: #007bff;
    cursor: pointer;
    /* border-radius: 4px; */
    margin-right: 1rem;
    margin-top: 1rem;
    /* border: 1px solid #007bff; */
    /* Border color and thickness */
    transition: background-color 0.5s ease, color 0.3s ease;
    background-color: transparent;
    overflow: hidden;
    /* transition: transform 0.3s ease, opacity 0.3s ease; */
    /* Smooth transition for background and text color */
}

.modal-button:hover {
    background-color: #007bff;
    /* border: 1px solid #007bff; */

    /* Darker shade of blue on hover */
    color: white;
}


@media (max-width: 768px) {
    .tanzator-service-item-container {
        width: 100%;
        aspect-ratio: 1;
    }

    .overlay-title {
        /* font-size: 1.25rem; */
    }

    .tanzator-service-content {
        padding: 2rem;
    }

    .modal-button {
        padding: 0.5rem 1rem;
        /* font-size: 0.875rem; */
    }
}