.staff-container {
    font-family: 'Quicksand', 'Lato', sans-serif;
    padding: 2rem;
    background-color: #f0f8ff;
    /* Light blue background */
    color: #333;
    /* Dark text color */
    max-width: 1200px;
    margin: 0 auto;
}

.staff-title {
    text-align: center;
    /* font-size: 3rem; */
    margin-bottom: 2rem;
    /* color: #007bff; Vibrant blue */
    font-size: 4rem;
    font-family: 'Courier Prime', monospace;
}

/* .team-list {
    display: grid;
    gap: 2rem;
}

.team-member-info {
    padding: 0 1rem 0.5rem 1rem;
    font-family: 'Roboto', sans-serif;
}

.team-member-link {
    text-decoration: none;
    color: inherit;
    display: flex;
}

.team-member-card {
    background: linear-gradient(45deg, lightblue, lavender);
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.team-member-card:hover {
    transform: translateY(-5px);
    background: lightblue;

}

.image-container {
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}

.team-member-image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
}
.team-member-image:hover {
    transform: scale(1.1);

} */

.services-distributed {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    /* height: 80vh; */
}

.team-section-content {
    font-size: 1.2rem;
    /* line-height: 1.8; */
    color: #555;
    animation: fadeIn 1s ease-in;
    padding: 2rem;
}

.team-section-introduction {
    /* padding: 5%; */
    justify-items: center;
    text-align: center;
}

.about-team {
    background: linear-gradient(45deg, #f0f8ff, lavender);
    /* padding: 2rem; */
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    animation: fadeIn 1s ease-in;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
}

.about-team :hover {
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), #f0f8ff, lavender);

}

.about-team h2 {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    /* color: #007bff; */
    /* margin-bottom: 1rem; */
    display: flex;
    align-items: flex-start;
}

.about-team ul {
    list-style-type: disc;
    margin-left: 2rem;
    color: #555;
}

.about-team li {
    margin-bottom: 0.5rem;
}

.image-sections-team {
    padding:5% 20%;
    height: 60px;
}



/* Full-width image for smaller screens */
@media screen and (max-width: 768px) {
    .staff-container {
        padding: .5rem;
    }

    .team-member-card {
        width: auto;
    }

    .image-container {
        height: auto;
    }



    .team-section-content {
        padding: 1rem;
    }

    .about-team h2 {
        /* font-size: 2rem; */
        align-items: baseline;
    }



    .services-distributed {
        flex-direction: column;
        gap: 0;
    }

}

@media (max-width: 480px) {
    .about-team h2 {
        /* font-size: 1.5rem; */
    }

}




.about-services-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
}

.ant-collapse {
    background: linear-gradient(45deg, #f0f8ff, lavender);
}

.ant-collapse-header {
    font-size: 1rem;
    font-weight: '600';
    /* vertical-align: middle; */
    font-family: 'Quicksand', 'Lato', sans-serif;

}

.ant-collapse-item {
    border-radius: 3px;
    margin-bottom: 10px;
    overflow: hidden;
}

.ant-collapse-content.ant-collapse-content-active {
    background-color: lavender;
    padding: 0;
}

.ant-collapse-content.ant-collapse-content-active :hover {
    background: lavender;
    /* padding: 0; */
}

.ant-collapse-content {
    font-size: 1rem;
    /* line-height: 1.6; */
    /* padding: 10px; */
    font-family: 'Quicksand', 'Lato', sans-serif;
}

.about-team p {
    font-size: 0.875rem;
    font-family: 'Quicksand', 'Lato', sans-serif;

}
/* Make sure it's responsive */
@media (max-width: 768px) {

    .about-services-title {
        /* font-size: 1.5rem; */
    }

    .ant-collapse-header {
        /* font-size: 1rem; */
    }

    .ant-collapse-content {
        /* font-size: 0.9rem; */
    }
}