
.projects-container {
    font-family: 'Quicksand', 'Lato', sans-serif;
    padding: 2rem;
    background-color: #f0f8ff;
    /* Light blue background */
    color: #333;
    /* Dark text color */
    max-width: 1200px;
    margin: 0 auto;
}

.projects-title {
    text-align: center;
    font-size: 4rem;
    font-family: 'Courier Prime', monospace;
    margin-bottom: 2rem;
    /* font-size: 3rem;
    color: #007bff; */
    /* Vibrant blue */
}

.projects-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 10vh;
}

.projects-list-title {
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.project-card {
    background: linear-gradient(45deg, lightblue, lavender);
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    padding: 0;
    border: 2px solid white;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
    background: lightblue;

}

.project-link {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: 100%;
    text-decoration: none;
    color: inherit;
}

.project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-bottom: 0;
    border-radius: 0;
}

.project-details {
    padding: 0 10%;
    /* padding: 2rem; */

    /* padding: 0 1rem .5rem 1rem; */
}

.project-name {
    font-size: 1.375rem;
    margin-bottom: 0.5rem;
}

.project-type {
    font-size: 1.5rem;
    color: #007bff;
    /* Vibrant blue */
    margin-bottom: 1rem;
}

.project-description {
    font-size: 1.2rem;
    /* line-height: 1.6; */
    color: #555;
    /* Medium gray */
    margin-bottom: 1rem;
}
.project-description-clamped {
    /* font-family: 'Roboto', sans-serif; */
    font-size: 0.875rem;
    /* line-height: 1.8; */
    color: #555;
    display: -webkit-box;            /* Enables the box model needed for line clamping */
    -webkit-box-orient: vertical;    /* Sets the box's orientation to vertical */
    -webkit-line-clamp: 5;           /* Limits the text to 2 lines */
    overflow: hidden;                /* Hides any overflowing text */
    text-overflow: ellipsis;         /* Adds ellipsis (…) to indicate clipped text */
    line-height: 1.5;               
    /* max-height: 3em;                 //Sets a max height based on the line height and line clamp */ 
    font-family: 'Quicksand', 'Lato', sans-serif;
}


.project-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0 .5rem 1rem .5rem;
    flex-wrap: wrap;
}

.preview-link {
    background: white;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    /* background-color: #007bff; Vibrant blue button */
    color: #007bff;
    text-decoration: none;
    border: 2px solid #007bff;
    border-radius: 3px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
    overflow: hidden;
    height: 40px;
}

.preview-link .icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
}

.preview-link span {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.preview-link:hover {
    background-color: #007bff;
    /* Darker shade of blue on hover */
    border-color: #007bff;
    transform: scale(1.05);
    color: white;
    cursor: pointer;
}

.preview-link:hover::after {
    opacity: 1;
    transform: scaleX(1);
}

.preview-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 4px;
    background: white;
    opacity: 0;
    transform: scaleX(0);
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform-origin: center;
}

/* .preview-link .icon {
    flex-shrink: 0;
} */

.preview-link span {
    flex-grow: 1;
    white-space: nowrap;
}

.preview-link span .small-text {
    font-size: 0.75rem;
    line-height: 1.2;
    /* margin-bottom: 0.25rem; */
}

.preview-link span .main-text {
    font-size: 1rem;
    line-height: 1.5;
}

/* Specific styles for "Download on the App Store" and "Get it on Google Play" */
.preview-link a[title="appStore"] span::before {
    content: "Download on the";
    display: block;
    font-size: 0.75rem;
    line-height: 1.2;
}

.preview-link a[title="playStore"] span::before {
    content: "Get it on";
    display: block;
    font-size: 0.75rem;
    line-height: 1.2;
}


.see-more-span {
    position: relative;
    font-size: 1rem;
    border: none;
    color: #007bff;
    cursor: pointer;
    border-radius: 3px;
    transition: color 0.3s ease;
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-family: 'Quicksand', 'Lato', sans-serif;
}

.see-more-span::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -3px;
    width: 0;
    height: 2px;
    background-color: #007bff;
    transition: width 0.3s ease, left 0.3s ease;
}

.see-more-span:hover::after {
    width: 100%;
    left: 0;
}

.see-more-span:hover {
    color: #007bff;
    /* Change text color on hover */
}



@media (max-width: 768px) {
    .projects-container {
        padding: .5rem;
    }
}