/* Topbars.css */

/* .container {
    font-family: 'Roboto', sans-serif;
} */

.topnav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rgb(230, 230, 250,.6); */
    /* padding: .1rem 0; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    position: sticky;
    /* Make the navbar sticky */
    top: 0;
    /* Stick to the top of the viewport */
    z-index: 1000;
    /* Ensure it appears above other content */
    /* transition: all 1.2s ease; */
    transition: backdrop-filter 0.3s ease;
    font-family: 'Muli', 'Poppins', sans-serif;

}

.topnav.scrolled {
    backdrop-filter: blur(10px);
}

.rotate-right {
    transform: rotate(360deg);
    /* Moves the logo down by 50px */
    scale: 1.1;
}

.rotate-left {
    transform: rotate(0);
    scale: 1;
    /* Moves the logo down by 50px */
}

.logo {
    display: block;
    transition: transform 1.2s ease, opacity 1.2s ease;
    margin-left: 1rem;
}

.hide-logo {
    transform: translateY(100%);
    opacity: 0;
}

.show-logo {
    transform: translateY(0);
    opacity: 1;
}

.logo-img {
    height: 4rem;
    width: 4rem;
    transition: transform 0.5s ease-in-out;
}

.logo-img:hover {
    transform: rotate(360deg);
}

.bars {
    display: none;
    cursor: pointer;
    font-size: 1.875rem;
    /* color: #007bff; */
    margin-right: 1rem;
    /* font-weight: 900; */
    white-space: nowrap;
    user-select: none;
    /* Prevent text selection on double-click */
    /* z-index: 10000; */
    align-self: center;
}

.closeBars {
    display: none;
    cursor: pointer;
    font-size: 1.875rem;
    /* color: #000; */
    margin-right: 1rem;
    /* font-weight: 900; */
    white-space: nowrap;
    user-select: none;
    /* Prevent text selection on double-click */
    align-self: center;

    z-index: 5;
}

.hamburger-menu {
    font-size: 2rem;
}

.bars:active .hamburger-menu,
.bars:focus .hamburger-menu,
.bars:hover .hamburger-menu {
    color: inherit;
    /* Maintain original color */
    background-color: transparent;
    /* No background color change */
    outline: none;
    /* Remove outline */
    border: none;
    /* Remove border */
}

/* 
.menu {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    transition: transform 1.2s ease, opacity 1.2s ease;

    transition: all 0.7s ease; 


} */

.menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    transition: all 0.5s ease;
    position: relative;
    margin: 1rem;
}


.menu.centered {
    /* transition: justify-content 0.5s ease; */
    justify-content: center;
    flex-grow: 1;
}

.link {
    color: black;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s, transform 0.3s;
}

.link:hover {
    color: #007bff;
    transform: scale(1.1);
}

.active {
    /* color: #007bff; */
    transform: scale(1.1);
    border-bottom: 1px solid;
    padding: 5px;
}

@media (max-width: 768px) {
    .bars {
        display: block;
    }

    .closeBars {
        display: block;
    }

    .topnav {
        padding: 0;
        align-items: end;
    }

    .logo-img {
        height: 3rem;
        width: 3rem;
        z-index: 5;
    }

    .menu {
        display: none;
        flex-direction: column;
        gap: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        background-color: lavender;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        /* padding: 0 1rem; */
        width: 100%;
        height: 100vh;
        margin: 0;
        justify-content: center;

    }

    .menu.open {
        display: flex;
        width: 100%;
    }

    .menu.centered {
        position: relative;
        /* Adjust for centered menu */
        top: 0;
        left: 0;
        width: auto;
    }

    .link {
        color: black;
        text-decoration: none;
        font-size: 1.5rem;
        transition: color 0.3s, transform 0.3s;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

    .link:hover {
        color: #007bff;
        transform: scale(1.1);
    }

    .active {
        background: #007bff;
        color: #fff;
        /* transform: scale(1.1); */
        font-weight: 600;
        border: none;
    }

    .active:hover {
        background: #007bff99;
        color: #fff;
    }
}

@media (min-width: 769px) {
    .menu {
        display: flex;
    }

    .menu.open {
        display: flex;
    }
}

@media (min-width: 1024px) {
    .menu {
        display: flex;
        margin-right: 1rem;
    }

    .menu.open {
        display: flex;
    }
}